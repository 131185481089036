<template>
  <div class="body" id="loader">
    <div class="front wheel"></div>
    <div class="back wheel"></div>
    <div class="cover">
      <div class="path"></div>
    </div>
  </div>
  <div class="text" style="padding-right: -10px">
    Loading Data, Please Wait...
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style scoped>
/* https://www.youtube.com/watch?v=ZAl85Ec2lSs */
.body {
  height: 70px;
  width: 140px;
  background: #68b5ca;
  border-radius: 5px;
  position: relative;
  animation: animate 0.5s linear infinite;
}

.body:before {
  position: absolute;
  content: "";
  height: 40px;
  width: 60px;
  /* background: #181821; */
  top: -10px;
  right: -20px;
  transform: rotate(40deg);
  border-radius: 0 0 0 50px;
}

@keyframes animate {
  100% {
    transform: translateY(2px);
  }
}

.body .wheel {
  height: 40px;
  width: 40px;
  background: #68b5ca;
  /* border: 4px solid #181821; */
  position: absolute;
  border-radius: 50px;
  margin-left: 50px;
  bottom: -20px;
  animation: wheels 0.6s linear infinite;
}

.body .wheel:before {
  position: absolute;
  content: "";
  /* background: #181821; */
  height: 5px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.body .wheel:after {
  position: absolute;
  content: "";
  /* background: #181821; */
  height: 5px;
  width: 100%;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

@keyframes wheels {
  100% {
    transform: rotate(360deg);
  }
}

.body .front {
  position: absolute;
  margin: 0 80px;
}

.body .back {
  position: absolute;
  margin: 0 10px;
}

.cover {
  position: relative;
  height: 10px;
  width: 200px;
  bottom: -91px;
  left: -23px;
  overflow: hidden;
}

.cover .path {
  height: 8px;
  width: 600%;
  background: #68b5ca;
  position: absolute;
  animation: path 1.5s linear infinite;
}

.path:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 30px;
  /* background: #181821; */
  left: 20%;
}

.path:after {
  position: absolute;
  content: "";
  height: 8px;
  width: 30px;
  /* background: #181821; */
  right: 60%;
}

@keyframes path {
  100% {
    transform: translateX(-50%);
  }
}

.text {
  padding-top: 40px;
  font-size: 16px;
}
</style>
